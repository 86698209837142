@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto+Mono:wght@400;500&display=swap');

html{
    @apply text-sm sm:text-base font-sans;

    @media (-webkit-device-pixel-ratio: 1.25) {
        @apply sm:text-xs
    }

    @media only screen and (max-height: 900px) {
        @apply text-xs
    }

}

*{
    @apply font-sans;
}

body{
    @apply font-sans;
}

.router-link{
    @apply no-underline;
}

.err-msg{
    @apply text-sm mt-2 block font-medium text-imperialRed;
}

a{
    @apply no-underline;
}

.border-enable{
    @apply border-b border-0 border-solid border-b-borderGrayDark;
}

.min-height-grid{
    min-height: calc(100vh - 12.75rem );
    max-height: calc(100vh - 12.75rem );
    overflow-y: auto;
}

.min-height-grid-collapse{
    min-height: calc(100vh - 20.45rem );
    max-height: calc(100vh - 20.45rem );
    overflow-y: auto;
}

.min-height-grid-collapse-both{
    min-height: calc(100vh - 25.45rem );
    max-height: calc(100vh - 25.45rem );
    overflow-y: auto;
}

.min-height-grid-no_org{
    min-height: calc(100vh - 19.75rem );
    max-height: calc(100vh - 19.75rem );
    overflow-y: auto;
}

.min-height-settings, .min-height-uploadView{
    min-height: calc(100vh - 17.25rem );
    max-height: calc(100vh - 17.25rem );
    overflow-y: auto;
}

.min-height-grid-settings-right{
    min-height: calc(100vh - 9.6rem );
    max-height: calc(100vh - 9.6rem );
    overflow-y: auto;
}

.min-height-grid-settings{
    min-height: calc(100vh - 4.125rem );
    max-height: calc(100vh - 4.125rem );
    overflow-y: auto;
}

.min-height-grid-map-variable{
    min-height: calc(100vh - 18.75rem );
    max-height: calc(100vh - 18.75rem );
    overflow-y: auto;
}

.min-height-grid-map-field{
    min-height: calc(100vh - 18rem );
    max-height: calc(100vh - 18rem );
    overflow-y: auto;
}

.min-height-uploadView-uncheck{
    min-height: calc(100vh - 11.3rem );
    max-height: calc(100vh - 11.3rem );
    overflow-y: hidden;
}

.min-height-docView{
    .simplebar-wrapper{
        min-height: calc(100vh - 21.3rem );
        max-height: calc(100vh - 21.3rem );
        overflow-y: hidden;
    }
}


.min-height-docView-uncheck{
    .simplebar-wrapper{
        min-height: calc(100vh - 15.5rem );
        max-height: calc(100vh - 15.5rem );
        overflow-y: hidden;
    }
}

.min-height-docView-uncheck{
    min-height: calc(100vh - 15.5rem );
}

.height-docView{
    max-height: calc(100vh - 21.3rem );
    min-height: calc(100vh - 21.3rem );
}

.height-docView-uncheck{
    max-height: calc(100vh - 15.5rem );
    min-height: calc(100vh - 15.5rem );
}

.min-height-upload{
    min-height: calc(100vh - 11.375rem );
    max-height: calc(100vh - 11.375rem );
    overflow-y: auto;
}

.min-height-grid-pdf-viewer{
    min-height: calc(100vh - 12.75rem);
    max-height: calc(100vh - 12.75rem);
    overflow-y: auto;
}

.remove-pad{
    .MuiBox-root{
        padding: 0;
    }
}

.doc-review{
    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }
}



//125% scale issue fix

    .container-up-margin{
        @media (-webkit-device-pixel-ratio: 1.25) {
            margin-top: 6rem !important;
        }
    }

    .min-height-grid-map-variable{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 20.75rem );
            max-height: calc(100vh - 20.75rem );
        }
    }

    .min-height-grid-map-field{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 20rem );
            max-height: calc(100vh - 20rem );
        }
    }

    .min-height-settings, .min-height-uploadView{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 19.75rem);
            max-height: calc(100vh - 19.75rem);
        }
    }

    .min-height-grid-settings{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 6.125rem );
            max-height: calc(100vh - 6.125rem );
        }
    }


    .min-height-uploadView-uncheck{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 13.75rem );
            max-height: calc(100vh - 13.75rem);
            overflow-y: hidden;
        }
    }

    .min-height-uploadView{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 19.75rem);
            max-height: calc(100vh - 19.75rem);
        }
    }

    .min-height-docView{
        .simplebar-wrapper{
            @media (-webkit-device-pixel-ratio: 1.25) {
                min-height: calc(100vh - 24rem);
                max-height: calc(100vh - 24rem);
            }
        }
    }

    .min-height-docView-uncheck{
        .simplebar-wrapper{
            @media (-webkit-device-pixel-ratio: 1.25) {
                min-height: calc(100vh - 18rem);
                max-height: calc(100vh - 18rem);
                overflow: hidden;
            }
        }
    }

    .min-height-grid{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 14.75rem);
            max-height: calc(100vh - 14.75rem);
        }
    }

    .min-height-grid-collapse{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 22.4rem );
            max-height: calc(100vh - 22.4rem );
        }    
    }

    .min-height-grid-collapse-both{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 27.4rem );
            max-height: calc(100vh - 27.4rem );
        }    
    }

    .min-height-grid-no_org{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 21.625rem );
            max-height: calc(100vh - 21.625rem );
        } 
    }

    .min-height-upload{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 13.375rem);
            max-height: calc(100vh - 13.375rem);
        }
    }

    .min-height-grid-pdf-viewer{
        @media (-webkit-device-pixel-ratio: 1.25) {
            min-height: calc(100vh - 14.75rem);
            max-height: calc(100vh - 14.75rem);
        }
    }

.scroll-light {
    .simplebar-scrollbar{
        &::before{
            background-color: #fff !important;
        }
    }
}

.input-row{
    &:nth-last-child(-n+2) {
        @apply border-b-0 #{!important};
    }

    &.input-map{
        &:nth-child(1){
            .section {
                @apply pt-0 #{!important};
            }
        }

        &:nth-child(2){
            .section {
                @apply pt-0 #{!important};
            }
        }
    }
}

.search-input{
    input{
        @apply pl-0 font-medium placeholder:text-primary placeholder:font-semibold py-4;
    }
}

.addField{
    .MuiInputBase-formControl{
        @apply h-0;
    }
}

.imgBtn-mr-0{
    img{
        @apply me-0 #{!important};
    }
}

.compliant-alert{

    @apply shadow-none #{!important};

    .MuiAlert-message{
        @apply flex items-center;
    }
}

.CompliantAlert-wrapper{
    @media (-webkit-device-pixel-ratio: 1.25) {
        @apply top-[1.125rem] font-medium #{!important} ;
    }
}

.state-fulvous{
    @apply text-fulvous;
}

.state-crayola{
    @apply text-crayola;
}

.state-red{
    @apply text-red;
}

.state-primary{
    @apply text-primary;
}

.state-lace{
    @apply bg-lace;
}

.state-aqua{
    @apply bg-aqua;
}

.state-cultured{
    @apply bg-cultured;
}

.state-linen{
    @apply bg-linen;
}

.state-sonicSilver{
    @apply text-sonicSilver;
}

.state-imperialRed{
    @apply text-imperialRed;
}

.state-cultured{
    @apply bg-cultured;
}

//data grid

.MuiTablePagination-displayedRows {
    @apply text-primary text-xs font-medium #{!important};
}

#menu-{
    @apply mt-2;

    .type-filter{
        @apply hidden;
    }
}

.type-filter-select{
    .type-filter-item-filter{
        @apply inline mr-2 text-sonicSilver;

    }
}

.filter-hide{
    @apply hidden;
}

.type-filter-item{

    &:not(:last-child) {
        @apply border-b border-0 border-solid border-b-borderGrayDark #{!important};
    }

    &.review-doc{
        &:first-child {
            @apply mt-2 rounded-t-xl;
        }
    }
}

.type-filter-item-with-add{
    @apply border-b border-0 border-solid border-b-borderGrayDark #{!important};

    &:first-child {
        @apply mt-2 rounded-t-xl;
    }
}

//tooltip info - review doc

.action-info{
    box-shadow: 0px 5px 5px -3px #0000000d, 0px 8px 10px 1px #0000000d, 0px -3px 14px 2px rgb(0 0 0 / 12%);
    background-color: #fff !important;

    .info-row{
        @apply p-1;

        .title{
            @apply text-sm text-sonicSilver;
        }
        .detail{
            @apply text-sm text-primary font-semibold;
        }
    }

    .MuiTooltip-arrow{
        @apply text-white #{!important};
    }
}

.bg-lock-pattern{
    background: repeating-linear-gradient( -45deg, #f5f8f8, #f5f8f8 1px, #ffffff 1px, #ffffff 10px );
}

code{
   //@apply font-medium font-xml text-primary;
}

.highligted{
    @apply bg-eerieBlack w-full;
}

.format-selected{
    @apply border border-primary border-solid #{!important};
}

.domain-adornment{
    .MuiTypography-root{
        @apply font-semibold text-primary pl-4 border-l border-0 border-[#E3E3E3] border-solid;
    }
}

//curser animation for chat gpt typing effect

@keyframes blink {
    from{
        opacity: 0%;
     }
     to{
         opacity: 100%;
      }
}

.type-writer{
    &::after{
        content: "|";
        animation: blink 1s infinite;
    }
}

// .MuiDataGrid-row{
//     & > .MuiDataGrid-cell{
//         &:first-child{
//             @apply hidden;
//         }
//     }
// }

// .MuiDataGrid-columnHeader{
//     &:first-child{
//         @apply hidden #{!important};
//     }
// }

//scroll bar

::-webkit-scrollbar, #pages-view::-webkit-scrollbar{
    right: 0;
    width: 6px;
    height: 8px;
}

::-webkit-scrollbar-thumb, #pages-view::-webkit-scrollbar-thumb{
    background-color: #E3E3E3;
    border-radius: 6px;
    width: 6px;
    height: 8px;
}

::-webkit-scrollbar-track, #pages-view::-webkit-scrollbar-track{
    background-color: transparent;
}


#adobe-dc-view{
    min-height: calc(100vh - 12.75rem);
    max-height: calc(100vh - 12.75rem);
    height: calc(100vh - 12.75rem);
}

.bg-color{
    @apply bg-eerieBlack w-full;
}


//cursor span

#cursorIndicator{
    border-left:1px solid #fff ;
    animation-name: blinker;
     animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1,0,0,1);
    animation-duration: 1s;
}

@keyframes blinker { 
    from { opacity: 1.0; } to { opacity: 0.0; }
}

//map field

#muiTableWrapper{
    .cellNotMap {
        @apply bg-seashell #{!important};

        &.header{

            .Mui-TableHeadCell-Content{
                background: url('../assets/line-danger.svg') no-repeat 0 0.25rem;
                padding-left: 1.75rem;
                background-size: 1rem;
            }
             
        }
    }
}

.region-select{
    .filter-chk-hide{
        @apply hidden #{!important};
    }
}

//date pickers

.MuiPickersPopper-root{
    @apply mt-2 #{!important};

    .MuiPaper-root{
        @apply border-solid border-primary border shadow-none;
    }

    .MuiDayCalendar-weekDayLabel{
        @apply font-sans text-sonicSilver text-sm font-medium;
    }

    .MuiPickersCalendarHeader-label{
        @apply font-sans text-primary text-base font-medium;
    }

    .MuiPickersDay-root, .MuiPickersYear-yearButton{
        @apply font-sans text-primary text-base font-medium;

        &:hover, &:focus{
            @apply bg-secondary bg-opacity-20 #{!important};
        }

        &.MuiPickersDay-today{
            @apply border-secondary;
        }

        &.Mui-selected{
            @apply bg-secondary text-white #{!important};
        }
    }
    

}

.dropdown-checkbox{
    @apply pr-2 p-0 #{!important};
}

.type-filter-item-filter{
    &:not(:first-child){
        @apply hidden #{!important};
    }
}

.settigs-not-access{
    height: calc(100vh - 16rem);
}

.mand-mark{
    @apply text-base text-imperialRed;
}

.log-container{

    @apply border-b border-0 border-solid border-borderGrayDark;

    &:last-child{
        @apply border-0;
    }

    .log-item{
        &:last-child{
            @apply mb-0 #{!important};
        }
    }
}

//nav bar dropdown

#menu-navBar{
    .MuiMenu-paper{
        @apply rounded-[1.25rem] bg-white;
    }
}

.main-nav{
    .MuiPaper-root{
        box-shadow: 0px 5px 5px -3px #0000000d, 0px 8px 10px 1px #0000000d, 0px -3px 14px 2px rgb(0 0 0 / 12%) !important;
        border: none !important;
    }
}

.MuiMenu-root{
    .MuiBackdrop-root{
        @apply bg-transparent;
    }
}

.value-switch-tooltip{
    @apply text-secondary font-semibold ml-1 cursor-pointer text-sm;
}



.doc-review-table {
  
    .tableHead{
      @apply select-none
    }

    .tableCell {
      
      &.resizable{
        //@apply relative;
      }

      &:hover{
        .resizeLine{
            @apply bg-borderGrayDark;
        }
      }

    }
   
    .resizeLine{

        @apply absolute h-full w-1 top-0 right-0 cursor-col-resize ;

      &:hover{
       @apply bg-secondary rounded #{!important};
      }

      &:active {
        @apply bg-secondary rounded #{!important};
  
      }
    }
 
  }

.pick-packages-wrapper{
    .MuiToggleButton-root{
        @apply mr-7;
        
        &:last-child{
            @apply mr-7;
        }

        &.Mui-selected {
            @apply bg-[#F2FAF5] border-secondary border-[2px] border-solid #{!important};
        }
    }
}

.feature-cards-wrapper{
    .feature-card{
        @apply mr-7;
        
        &:last-child{
            @apply mr-7;
        }
    }
}

@media only screen and (max-height: 900px) {

    .MuiToolbar-root {
        @apply min-h-[4rem] #{!important};
    }
    
}

.pdf-viewer-container{
    @apply  h-[78.4vh] flex justify-center items-center;

    .rpv-core__doc-error-text{
        @apply break-words;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        @apply h-[76vh] flex;
    }
}