.min-h-knownMap{
    min-height: calc(100vh - 16.5rem );
    max-height: calc(100vh - 16.5rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none mx-4 outline-none;
    }
}



.min-h-knownMap-edit{
    min-height: calc(100vh - 22rem );
    max-height: calc(100vh - 22rem );
    overflow-y: auto;

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 23.25rem);
        max-height: calc(100vh - 23.25rem);
    }

    .simplebar-content-wrapper{
        @apply rounded-none mx-4 outline-none;
    }
}

.min-h-knownMap-xmlView-edit{
    min-height: calc(100vh - 13rem);
    max-height: calc(100vh - 13rem);
    overflow-y: auto;

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 14.25rem);
        max-height: calc(100vh - 14.25rem);
    }

    .simplebar-content-wrapper{
        @apply rounded-none mx-4 outline-none;
    }
}

.min-h-knownMap-xmlView{
    min-height: calc(100vh - 8.125rem);
    max-height: calc(100vh - 8.125rem);
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none mx-4 outline-none;
    }
}

.min-h-unknownMap{
    min-height: calc(100vh - 16rem );
    max-height: calc(100vh - 16rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }
}

.min-h-region{
    min-height: calc(100vh - 17rem );
    max-height: calc(100vh - 17rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 19rem );
        max-height: calc(100vh - 19rem );
    }
}

.min-h-instruct{
    min-height: calc(100vh - 20.75rem );
    max-height: calc(100vh - 20.75rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 22.75rem );
        max-height: calc(100vh - 22.75rem );
    }
}

.min-h-setting-right-active{
    max-height: calc(100vh - 12.5rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none mx-5 outline-none;
    }
}

.min-h-user-setting-right-active{
    max-height: calc(100vh - 12.5rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none mx-5 outline-none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 15.5rem );
        max-height: calc(100vh - 15.5rem );
    }
}

.menu-item-center{
    @apply justify-center #{!important};
}

.settings-tab{
    .MuiTabs-flexContainer{
        @apply border-0 w-full;
    }

    .MuiTab-root{
        &.Mui-selected{
            @apply bg-culturedGreen text-secondary #{!important};
        }
    }
    
    
}

.known-map-search{
    input{
        @apply py-4;
    }
}

.fieldDropdownItem{
    @apply  bg-[url(../../assets/add.png)] bg-no-repeat bg-[96%] bg-[length:1.5rem_1.5rem] text-sm py-3 #{!important};

    &.Mui-selected{
        @apply bg-[url(../../assets/remove.png)] bg-no-repeat #{!important} ;
    }

    &.no-bg-img{
        @apply bg-none cursor-[unset] hover:bg-white #{!important};
    }
}


//instruct build

#menu-country{
    .MuiMenu-paper{
        @apply max-h-[calc(100%-60vh)] mt-2;

        .country-down-item{
            &:first-child{
                @apply mt-2 rounded-t-xl;
            }

            &:last-child{
                @apply mt-2 rounded-b-xl;
            }


        }
    }
}

.org-specific{
    &.subOrg {
        @apply pl-8;
    }

    &.mainOrg {
        @apply cursor-default pointer-events-none opacity-60;
    }

    &.item-multi{
        @apply pointer-events-auto opacity-100 #{!important};
    }
}

//report swiper

.report-summary{
    .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
        @apply hidden;
    }

    .swiper-button-next{
        background-image: url('../../assets/arrow-next.svg'), linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6979166666666667) 70%, rgba(255,255,255,0) 100%);
        height: 100%;
        top: 1.35rem;
        width: 4rem;
        right: 0;
        background-repeat: no-repeat;
        background-position: center;

        &::after{
            content: '';
        }
    }

    .swiper-button-prev{
        background-image: url('../../assets/arrow-next.svg'), linear-gradient(270deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.6979166667) 70%, rgba(255, 255, 255, 0) 100%);
        height: 100%;
        top: 1.35rem;
        width: 4rem;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);

        &::after{
            content: '';
        }
    }

    @media only screen and (max-height: 900px) {

        .swiper-button-next, .swiper-button-prev{
            top: 1.65rem;
            width: 5rem;
        }

    }
}

//table header first and last child border radius

.MuiTableCell-head{
    &:first-child{
        @apply rounded-tl-xl;
    }

    &:last-child{
        @apply rounded-tr-xl;
    }
}

//chart style

.MuiChartsAxis-line, .MuiChartsAxis-tick{
    @apply stroke-borderGrayDark #{!important};
}

.MuiChartsAxis-tickLabel, .MuiChartsAxis-label{
 @apply text-primary font-medium font-sans text-sm #{!important};
}

.MuiChartsAxis-label{
    @apply font-semibold  #{!important};

    text{
        @apply text-sm translate-y-2 #{!important};
    }
}

.MuiChartsTooltip-root{

    @apply rounded-xl #{!important};

    .MuiTypography-root{
        @apply text-primary font-semibold text-sm #{!important};
    }

    .MuiChartsTooltip-labelCell{
        &:nth-of-type(2){
            .MuiTypography-root{
                @apply text-secondary #{!important};
            }
        }
    }
}


.min-h-organization{
    min-height: calc(100vh - 16.5rem );
    max-height: calc(100vh - 16.5rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 18.5rem );
        max-height: calc(100vh - 18.5rem );
    }
}

.min-h-setting-report{
    min-height: calc(100vh - 8.5rem );
    max-height: calc(100vh - 8.5rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 10rem );
        max-height: calc(100vh - 10rem );
        padding-right: 1.5rem !important;
    }
}

//org settings accordion

.org-accordion{

    &::before{
        @apply bg-transparent #{!important};
    }

    &.Mui-expanded{
        .MuiAccordionSummary-root{
            @apply rounded-b-none;
        }
    }

    .MuiAccordionSummary-root{
        @apply rounded-b-[1.25rem] p-0;
    }

    .sub-org-list{

        @apply border-x border-0 border-borderGrayDark border-solid;

        &:not(:last-child){
            @apply border border-t-0 border-borderGrayDark border-solid;
        }

        &:last-child{
            @apply rounded-b-[1.25rem] font-sans;
        }
        
    }
}