.pdf-search{
    @apply pl-2 #{!important};

    input{
        @apply p-2 text-sm font-medium pl-0;
    }
}

.pdf-zoom-select{
    @apply bg-cultured font-sans rounded-lg text-sm border-0 #{!important};

    &.Mui-focused{
        .MuiOutlinedInput-notchedOutline {
            @apply border-silver outline-none #{!important};
        }
    }

    .MuiSelect-select {
        @apply py-2 pl-0 pr-9 font-medium text-sonicSilver #{!important};
    }


}

.pdfZoomMenu{
    @apply p-3 font-medium text-sm #{!important};
}

