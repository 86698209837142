.min-h-xml-viewer{
    min-height: calc(100vh - 15.375rem);
    max-height: calc(100vh - 15.375rem);
}

.xml-view-wrapper{
    @apply bg-primary;

    pre{
        @apply font-normal bg-transparent w-full mx-0 my-1 p-6 pr-0 #{!important};

        code{
            @apply font-sans #{!important};

            .hljs-tag{
                @apply text-sonicSilver font-sans;

                &.hljs-name{
                    @apply text-munsellGreen;
                }

                &.hljs-string, &.hljs-attr{
                    @apply text-cerise;
                }
            }
        }
    }
}


// .xml-view-wrapper{
//     @apply bg-primary;

//     pre{
//         @apply mt-0 ;
//         code{
//             @apply pb-6;
//         }
//     }
// }

.editor-custom{
    min-height: calc(100vh - 15.375rem);
    max-height: calc(100vh - 15.375rem);
    width: 100% !important;
}