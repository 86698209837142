.modal-base{
   @apply absolute top-1/2 left-1/2 translate-y-1/2M translate-x-1/2M w-full outline-none;

   &.modal-loader{
      @apply max-w-[36rem] p-3;
   }

   &.modal-sent{
      @apply max-w-[28.75rem] p-3;

      .icon-wrapper{
         @apply h-20;

         .icon-img{
            @apply w-20;
         }
      }
   }

   &.modal-mapping, &.modal-secret-key{
      @apply max-w-[40rem] p-3;

      .modal-Header1{
         @apply mb-3;
      }

      .content-wrapper{
         @apply pb-0;
      }

      .footer-wrapper-center{
         @apply px-10 pb-10 pt-0 flex justify-between;
      }

   }   

    &.modal-org-setting{
      @apply max-w-[52rem] p-3;

      .modal-Header1{
         @apply mb-3;
      }

      .content-wrapper{
         @apply pb-0;
      }

      .footer-wrapper-center{
         @apply px-10 pb-10 pt-0 flex justify-between;
      }

   }  

   &.modal-verfy-output {
      @apply max-w-[28.75rem] p-3;

      .icon-img{
         @apply max-w-[5.625rem] min-w-[5.625rem] text-center;
      }

      .footer-wrapper-center{
         @apply pt-0 pb-5 flex justify-center;

         button{
            @apply w-full;
         }
      }      
   }

   &.modal-disconnect{
         @apply max-w-[29rem] p-3;

         .icon-img{
            @apply max-w-[5.625rem] min-w-[5.625rem] text-center
         }

         .footer-wrapper-center{
            @apply pt-0 pb-5 flex justify-between;

            button{
               @apply w-[48%] text-center;
            }
         }      
      }

   &.modal-save-changes{

      .icon-wrapper{
         @apply my-4 #{!important};

         .icon-img{         
            max-width: 7.625rem;
            min-height: 7.625rem;
         }
      }
      
   }   

   &.modal-delete, &.modal-logout,  &.modal-save-changes, &.modal-feature-soon{
      @apply max-w-[28.75rem] p-3;
      .icon-img{         
         max-width: 5.625rem;
         min-height: 5.625rem;
      }

      .icon-img-max-w-7{
         max-width: 7rem;
         min-height: 7rem;
      }

      .icon-img-flip{
         @apply max-w-[5.625rem] min-h-[5.625rem] rotate-180;
      }
      

      .footer-wrapper-center{
         @apply px-5 pb-7 pt-0 flex justify-between;

         button{
            @apply w-full;

            &:first-child{
               margin-right: 2%;
            }

            &:last-child{
               margin-left: 2%;
            }
         }
      }
   }

   &.modal-tag-review{
      @apply max-w-[40rem] p-3;

      .footer-wrapper-center{
         @apply px-10 pb-10 pt-0 flex justify-between;
      }
   }

}

.modal-container{
    @apply bg-white w-full rounded-3xl drop-shadow-modal;

    &.modal-loader{
      @apply border-0 p-5;
    }

     .modal-Header2{
        @apply p-5 py-7;
     }

     .modal-Header1{
      @apply text-2xl;
     }

     .content-wrapper{
        @apply p-10;

        &.modal-loader{
         @apply flex p-0 justify-between items-center rounded-3xl;
         //border border-solid border-cultured
       }

       .modal-text-base{
         @apply text-base text-primary mt-5;
       }

        .modal-text{
            @apply leading-7 px-6 pt-5;
        }

        .delete-file{
            @apply text-sonicSilver mt-5;
        }

        .icon-wrapper{
            @apply flex justify-center my-8;

            .icon-bg{
               @apply flex justify-center bg-antiFlashWhite p-7 rounded-full;
            }

        }

        .loader-content{
            @apply flex items-center;

            .loader-bg{
               @apply flex justify-center bg-culturedLight items-center rounded-2xl max-w-[6rem] max-h-[6rem] min-w-[6rem] min-h-[6rem] mr-10 overflow-hidden;

               .loader-icon-width{
                  max-width: 5rem;
               }

               .loader-icon-scale{
                  max-width: 5rem;
                  transform: translate(1.5, 1.5) rotate( 1.5) skewX( 1.5) skewY(1.5) scaleX(1.5) scaleY(1.5);
               }

            }
        }

        .loader-btn{
            @apply mr-10;
        }

        .modal-sent-btn{
            @apply mt-10 flex justify-center;
        }
        
     }

     .footer-wrapper-end{
        @apply px-5 py-7 flex justify-end;
     }

     .footer-wrapper-center{
      @apply px-5 py-7 flex justify-center;
      }

}