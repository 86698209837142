.electronic-view{
    .min-h-xml-viewer{
        min-height: calc(100vh - 12.375rem);
        max-height: calc(100vh - 12.375rem);
    }
}

#input-xml{
    .hljs-tag{
        @apply block ml-4;
    }
}


.tagWrap {

    @apply font-sans m-0 p-0 text-base font-medium;
    
            pre{
                @apply font-normal bg-transparent m-0 p-0 overflow-hidden rounded-none #{!important};
            }

            code{
                @apply pb-0;
            }

            span{
                @apply font-sans m-0 p-0 text-base font-medium;
            }

            .hljs-tag{
                @apply text-sonicSilver font-sans;
            }

            .hljs-name{
                @apply text-munsellGreen;
            }

            .hljs-string, &.hljs-attr{
                @apply text-carolinaBlue;
            }
            
}

.indent-0{
    @apply pl-0;
}

.indent-1{
    @apply pl-4;
}

.indent-2{
    @apply pl-8;
}

.indent-3{
    @apply pl-12;
}

.indent-4{
    @apply pl-16;
}

.indent-5{
    @apply pl-20;
}

.indent-6{
    @apply pl-24;
}

.indent-7{
    @apply pl-28;
}

.indent-8{
    @apply pl-32;
}

.indent-9{
    @apply pl-36;
}

.indent-10{
    @apply pl-40;
}
