.modal-button-12{
    @apply w-[48%] text-center;
}

.modal-mt-12{
    @apply mt-12 #{!important};
}

.modal-mx-3{
    @apply mx-3 #{!important};
}

.modal-mt-2{
    @apply mt-2 #{!important};
}
.modal-mt-0{
    @apply mt-0 #{!important};
}

.modal-mt-8{
    @apply mt-8 #{!important};
}

.modal-mt-4{
    @apply mt-4 #{!important};
}

.modal-mt-10{
    @apply mt-10 #{!important};
}

.modal-mb-5{
    @apply mb-5 #{!important};
}

.modal-mb-2{
    @apply mb-2 #{!important};
}

.modal-mb-6{
    @apply mb-6 #{!important};
}

.modal-mb-10{
    @apply mb-10 #{!important};
}

.modal-font-semibold{
    @apply font-semibold  #{!important};
}

.modal-items-center{
    @apply items-center #{!important};
}

.modal-flex{
    @apply flex #{!important};
}

.modal-pointer{
    @apply cursor-pointer #{!important};
}

.modat-label-img{
    @apply max-w-[1.25rem] w-full mr-3 cursor-pointer #{!important};
}

.search-in-modal{
    @apply rounded-lg #{!important};
    input{
        @apply py-3;
    }
}


.dropdrown-in-model{
    @apply  whitespace-pre-line bg-none text-sm py-3 #{!important};

    &:first-child, &:last-child{
        @apply rounded-none #{!important};
    }

    &.Mui-selected{
        @apply bg-[url(../../assets/remove.png)] bg-[96%] bg-[length:1.5rem_1.5rem] bg-no-repeat #{!important} ;
    }
}

#serach-wrapper-in-modal{
    @apply max-w-full #{!important};
}

.form-control{
    @apply flex flex-col items-start mb-8;

    .modal-label{
        @apply font-semibold text-base;
    }
    .modal-input{
        @apply max-h-[3.375rem] h-auto;

        &.multiple-line{
            @apply max-h-full;
        }
    }

    .upload-input-text-wrapper{
        @apply flex items-center justify-between w-full;

        .download-text{
            @apply text-secondary font-semibold mb-2 bg-transparent border-none outline-none cursor-pointer;
        }
    }
}


.filepond-modal{
    @apply pb-3 #{!important};

    .filepond--wrapper{
        @apply w-full;

        .filepond--panel-root{
            @apply bg-transparent;
        }

        .filepond-btn-modal{
            @apply mb-0  bg-white rounded-xl border border-solid border-primary;
        }

        .filepond--drop-label{
            @apply bg-transparent #{!important};
        }

        .drag-text-wrapper{
            @apply text-primary font-sans font-semibold;

            .text-underline{
                @apply underline;
            }
        }

        .filepond--panel-center{
            @apply h-auto;
        }

        .filepond--drop-label{
            @apply bg-white;
        }

        .filepond--item{
            @apply bg-transparent;
            
            .filepond--file-wrapper{
                @apply bg-transparent;
            }
        }

        .filepond--file{
            @apply bg-primary rounded-xl;
        }
    }
}

.label-wrapper{
    @apply flex justify-between w-full mb-2;

    .err-msg{
        @apply mt-0;
    }
}

.format-destination-input{
    @apply w-full rounded-lg p-5 outline-none font-medium text-base;
}

.format-internal-input{
    @apply w-full rounded-lg p-5 font-medium text-base cursor-pointer;

    &.bg-lock-pattern{
        background: repeating-linear-gradient( -45deg, #f5f8f8, #f5f8f8 1px, #ffffff 1px, #ffffff 10px );
    }
}

.modal-scroll{
    @apply max-h-[9rem] break-words overflow-y-auto;
}

.format-internal-wrapper{
    @apply w-full rounded-lg border-solid border border-cultured;
}

.format-destination-wrapper{
    @apply w-full border-solid border rounded-lg;

    &.focus{
        @apply border border-primary border-solid bg-white #{!important};
    }

    &.greenBorder{
        @apply border-secondary bg-culturedGreen;
    }

    &.redBorder{
        @apply border-imperialRed bg-lavender;
    }
}

.tag-list-modal{
    @apply p-0 w-full border-solid border border-cultured rounded-lg font-medium text-base cursor-pointer break-words #{!important};

    .modal-exist-map{
        @apply max-h-[7rem] overflow-y-auto;
    }
}

.tags-country-list{
    .MuiAutocomplete-endAdornment{
        @apply top-3;
    }
}


.w-modal-full{
    @apply w-full;
} 

.select-modal{
    //@apply bg-white border border-solid border-primary font-sans rounded-xl mt-0 #{!important};

    &::after{
        //@apply border-0 #{!important};
    }

    &::before{
        //@apply border-0 #{!important};
    }

    .MuiSelect-select{
        //@apply bg-white rounded-xl py-[0.944rem] pl-[14px] pr-[32px] font-sans text-primary font-medium #{!important} ;
        @apply bg-cultured rounded-xl py-[0.944rem] pl-[14px] pr-[32px] font-sans text-primary font-medium #{!important} ;
    }

    svg{
        @apply right-2;
    }

} 

.type-filter-select{
    .type-filter-item-filter{
        @apply inline mr-2 text-sonicSilver;

    }
}

.modal-multiple-textarea{

    @apply bg-cultured rounded-xl;

    &.Mui-focused{
        @apply border border-solid border-borderGrayDark rounded-xl;
    }

    .MuiInputBase-root {
        &.Mui-focused{
            .MuiOutlinedInput-notchedOutline{
                @apply border-0 outline-none #{!important};
            }
        }
    }


    .MuiFormControl-root  {
        @apply max-h-[12rem] overflow-y-auto ;
    }

    .MuiAutocomplete-popupIndicator{
        @apply hidden;
    }
}

.mapping-file-dropdown{
    .MuiSvgIcon-root{
        @apply mr-3;
    }
}

.modal-font-medium{
    @apply font-medium;
}

.modal-org-subTitle{
    @apply font-semibold #{!important};

    .step-span{
        @apply mr-2 text-secondary;
    }

}

.min-h-organization-modal{
    min-height: calc(100vh - 22rem );
    max-height: calc(100vh - 22rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 20rem );
        max-height: calc(100vh - 20rem );
    }
}

.min-h-organization-modal-step2{
    min-height: calc(100vh - 38rem );
    max-height: calc(100vh - 38rem );
    overflow-y: auto;

    .simplebar-content-wrapper{
        @apply rounded-none outline-none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        min-height: calc(100vh - 36rem );
        max-height: calc(100vh - 36rem );
    }
}

.modal-w-49{
    @apply max-w-[49%] w-full;
}

.modal-justify-between{
    @apply justify-between;
}

.modal-mb-12 {
    @apply mb-12 #{!important};
}

.modal-mt-5{
    @apply mt-5 #{!important};
}

.modal-mt-7{
    @apply mt-7 #{!important};
}
 
.modal-flex-wrap{
    @apply flex-wrap;
}

#menu-role, #menu-type{
    .MuiPaper-root{
        @apply mt-2;
    }
}

.modal-rules-cus-skeleton{
    @apply w-[33.5rem] min-h-[12.125rem] rounded-xl #{!important};
}

.modal-rules-cus2-skeleton{
    @apply w-[33.5rem] min-h-[19.313rem] rounded;
}

.text-heading-main{
    @apply font-sans text-6xl text-primary font-semibold text-center mb-10 mt-5;
}

.modal-p-2{
    @apply p-2;
}

.modal-InputAdornment{
    @apply mx-2;

    img{
        @apply w-6;
    }
}

.apiCredential-modal{
    .MuiInputBase-input.Mui-disabled{
        -webkit-text-fill-color:#000;
    }

    .alert-bar-modal{
        @apply bg-latte py-4 px-5 rounded-xl font-sans flex items-start;

        img{
            @apply w-6 h-6 mr-5;
        }

        .alert-text{
            @apply text-primary text-sm font-medium font-sans;
        }
    }
}

#menu-organization-spec{
    .MuiPaper-root{
        @apply max-h-[50vh] text-base;
    }
}

.modal-btn-wrap-vertical{
    @apply flex flex-col justify-between items-center p-5 pt-0;
}

.modal-btn-p {
    @apply py-[1.125rem] p-6 #{!important};
}

.modal-btn-outline-p {
    @apply py-4 p-6 #{!important};
}

.modal-pl-0{
    @apply pl-0 #{!important}; 
}

.modal-py-0{
    @apply py-0 #{!important}; 
}