@tailwind base;
@tailwind components;
@tailwind utilities;

@import './common_styles';
@import './pdf-viwer';
@import './mui-tab';
@import './xml_viewer_styles';
@import './monokai.css';
@import './output_format';
@import './pdf_toolbar';
