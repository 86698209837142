/*
Monokai style - ported by Luigi Maselli - http://grigio.org
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0 0.5em;
  white-space: break-spaces;
  word-break: break-word;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
}

.hljs-tag,
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-strong,
.hljs-name {
  color: #00a67d;
  font-family: 'Roboto Mono', monospace;
}

.hljs-code {
  color: #2e95d3;
  font-family: 'Roboto Mono', monospace;
}

.hljs-class .hljs-title {
  color: white;
  font-family: 'Roboto Mono', monospace;
}

.hljs-attribute,
.hljs-symbol,
.hljs-regexp,
.hljs-link {
  color: #df3079;
  font-family: 'Roboto Mono', monospace;
}

.hljs-string,
.hljs-bullet,
.hljs-subst,
.hljs-title,
.hljs-section,
.hljs-emphasis,
.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
  color: #f92672;
  font-family: 'Roboto Mono', monospace;
}

.hljs-comment,
.hljs-quote,
.hljs-deletion,
.hljs-meta {
  color: #75715e;
  font-family: 'Roboto Mono', monospace;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-doctag,
.hljs-title,
.hljs-section,
.hljs-type,
.hljs-selector-id {
  font-weight: 400;
  font-family: 'Roboto Mono', monospace;
}
