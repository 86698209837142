.simplebar-content-wrapper{
    @apply rounded-xl outline-none;
}


.rpv-core__viewer{

    @apply font-sans text-primary text-sm;

    div, p, button {
        @apply font-sans text-primary text-sm;
        
    }

    button {
        &:hover{
            @apply bg-antiFlashWhite;
        }
    }

    #rpv-core__popover-body-inner-zoom{
        .rpv-core__menu-item{
            &:nth-child(2){
                @apply hidden;
            }
        }
    }

    button{
        @apply flex items-center;
    }

    .rpv-default-layout__body{
        @apply pt-12;

        .rpv-default-layout__toolbar{
            @apply px-[1.125rem] py-6 ;
        }
    }

    .rpv-core__page-layer::after{
        @apply shadow-none;
    }

    .rpv-default-layout__container{
        @apply border-0;
    }

    .rpv-default-layout__toolbar{
        @apply bg-white;
    }

    .rpv-core__tooltip-body-content{
        @apply font-sans text-white text-xs px-3;
    }

    .rpv-core__popover-body{
        box-shadow: 0px 5px 5px -3px #0000000d, 0px 8px 10px 1px #0000000d, 0px -3px 14px 2px rgb(0 0 0 / 12%);
        border: 0;
        border-radius: 0.75rem;
    }

    

    .rpv-default-layout__sidebar--ltr, .rpv-core__popover-body-arrow{
        @apply hidden;
    }

    .rpv-toolbar__left{
        .rpv-toolbar__item{
            .rpv-page-navigation__current-page-input, .rpv-toolbar__label{
                @apply hidden;
            }
            
        }
        .rpv-core__display--hidden{
                @apply hidden;
        }
    }

    .rpv-toolbar__right{
        .rpv-core__display--hidden{
            @apply hidden;
        }
    }

    #rpv-core__popover-body-toolbar-more-actions{
        .rpv-core__menu-item--disabled, .rpv-core__menu-divider{
            @apply hidden ;
        }

        [data-testid="scroll-mode__vertical-menu"],
        [data-testid="scroll-mode__page-menu"],
        [data-testid="scroll-mode__horizontal-menu"],
        [data-testid="scroll-mode__wrapped-menu"],
        [data-testid="properties__menu"]
        {
            display: none;
        }
    }

    .rpv-search__popover-label, .rpv-search__popover-footer-item{
        @apply hidden;
    }
    
    .rpv-search__popover-footer-button--ltr{
        button{
            @apply bg-primary text-white text-xs rounded-[0.375rem] font-medium;

            &:hover{
                @apply bg-[#333] text-white;
            }
        }
    }

    .rpv-search__popover-counter{
        @apply text-primary text-sm font-medium pe-3;
    }

    .rpv-search__popover{
        @apply w-80 flex justify-between;

        .rpv-search__popover-input-counter{
            @apply w-[14.5rem] mb-0;
        }
    }

    .rpv-core__textbox{
        @apply ps-2 pe-11 py-1 border-0 text-primary text-sm font-medium rounded-[0.375rem] bg-cultured;

        &:focus-visible{
            @apply outline-0 border border-solid border-silver;
        }
    }

    .rpv-core__menu-item{
        @apply px-3 py-2;

        .rpv-core__menu-item-icon--ltr{
            @apply pl-0;
        }
    }

    .rpv-core__menu-divider{
        @apply border-cultured;
    }

    
    
}

