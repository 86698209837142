.MuiTabs-root {
    .MuiTabs-flexContainer{
        @apply border border-platinum border-solid rounded-xl;

        .MuiTab-root{
            @apply text-primary;

            &.Mui-selected{
                @apply  bg-platinum;
            }

            &:first-child{
                @apply border-r border-0 border-solid border-r-platinum; 
                &.Mui-selected{
                    @apply rounded-l-xl;
                }
            }
            &:last-child{
                @apply border-l border-0 border-solid border-l-platinum; 
                &.Mui-selected{
                    @apply rounded-r-xl;
                }
            }

        }

        .MuiButtonBase-root{
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
        }
        
    }

    .MuiTabs-fixed{
        @apply flex items-center;
    }

    .MuiTabs-indicator{
        @apply hidden;
    }
}

.edit-hover{
    &:hover{
        .edit-mode{
            @apply  opacity-100 z-50;
        }
    }
    .edit-mode{
        @apply opacity-0 -z-10;
    }
}

.edit-mode{

    @apply duration-300 ease-in-out;

    .MuiTabs-root{

        .MuiTabs-flexContainer{
            @apply border border-primary border-solid min-h-[2rem] rounded-md;
        }

        .MuiTab-root{
            @apply text-primary text-xs px-3 bg-white py-1 min-h-[1.875rem] p-0;

            &.Mui-selected{
                @apply  bg-primary;
            }

            &:first-child{
                @apply border-r border-0 border-solid border-r-primary rounded-l-md; 
                &.Mui-selected{
                    @apply text-white rounded-l-sm;
                }
            }
            &:last-child{
                @apply border-l border-0 border-solid border-l-primary rounded-r-md; 
                &.Mui-selected{
                    @apply text-white rounded-r-sm;
                }
            }
        }
    }
}

.document-review, .terms-and-privacy {
    .MuiTabs-flexContainer{
        @apply border-0;

        .MuiTab-root{
            @apply px-12 py-4 border border-b-0 border-solid border-platinum capitalize text-lg font-sans font-medium;

            &:first-child{
                @apply rounded-bl-none rounded-tl-xl border-b-0 border-r-0 border border-solid border-platinum #{!important};
            }

            &:last-child{
                @apply rounded-br-none rounded-tr-xl border-b-0 border-l-0 border border-solid border-platinum #{!important};
            }

            &.Mui-selected{
                @apply  bg-primary text-white border-b-0 border-jungleGreen #{!important};
            }
        }
    }
}

.terms-and-privacy{
    .MuiAccordion-root{

        &::before{
            @apply hidden;
        }

        &.Mui-expanded{

            .MuiAccordionSummary-root{
                @apply bg-culturedLight #{!important};
            }

        }

    }
}