.nav-item-wrapper{

    @apply min-w-[200px] py-3 px-5 border-none #{!important};

    &:last-child{
        //border-top: 1px solid #E4E4E4;
        @apply pb-5 #{!important};
    }

    &:nth-child(4){
        //border-top: 1px solid #E4E4E4;
        @apply pt-5 #{!important};
    }

    &.nav-item-mobile{
        border-bottom: 1px solid #E4E4E4;
    }

    &.nav-item-text{
        @apply text-base pt-0 pb-5 #{!important};
    }

    &.nav-item-text-18{
        @apply text-lg font-semibold pb-0 pt-5 #{!important};
    }

    &.nav-item-text-gray{
        @apply text-base text-sonicSilver py-5 px-3 mx-2 border-y border-platinum border-solid border-0 #{!important};
    }

    .nav-item{
        @apply flex items-center ; 
    
        img, .mob-icon-mr{
            @apply mr-4;
        }

    }

    &.nav-item-border-b-0{
        @apply border-b-0 #{!important};
    }

}


.nav-img-width{
    max-width: 9rem;
}

.nav-text-sonicSilver{
    @apply text-sonicSilver #{!important};
}

.nav-item-capitalize{
    @apply capitalize;
}

.nav-item-logout-only{
    @apply mb-3 pt-5 border-borderGrayDark border-t border-solid border-0 #{!important};

    &:hover{
        @apply rounded-b-xl #{!important};
    }
}